import Image from '../image'
import React from 'react'
import {
  Content,
  Copy,
  FooterLink,
  Label,
  Logo,
  Sns,
  SnsIcon,
  Text,
  Wrapper,
  Container,
} from './style'
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EMAIL } from '../../const'

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Logo>
          <Image filename="logo/logo_new_w.png" />
        </Logo>
        <Content>
          <Label>お問合わせ</Label>
          <Text>{EMAIL}</Text>
        </Content>
        <Content>
          <Label>SNS</Label>
          <Sns>
            <SnsIcon to="https://twitter.com/takamagahara369">
              <FontAwesomeIcon icon={faTwitter} />
            </SnsIcon>
            <SnsIcon to="https://instagram.com/takamagahara_stone">
              <FontAwesomeIcon icon={faInstagram} />
            </SnsIcon>
          </Sns>
        </Content>
        <Content>
          <FooterLink to="/privacy">プライバシーポリシー</FooterLink>
          <br />
          <FooterLink to="/guide">ショッピングガイド</FooterLink>
          <br />
          <FooterLink to="/transaction">特定商取引法に基づく表記</FooterLink>
        </Content>
        <Copy>© Takamagahara since 2020</Copy>
      </Container>
    </Wrapper>
  )
}

export default Footer
