import React from 'react'
import { Link } from 'gatsby'
import {
  Close,
  CloseInner,
  CloseText,
  Container,
  Header,
  Label,
  LinkList,
  List,
  ListContainer,
  ListTitle,
  ListTitleLine,
} from './style'
import Image from '../image'
import { TextSlideUp } from '../../utils/styles'

export const Drawer = ({ closeDrawer, isOpen }) => {
  return (
    <Container isOpen={isOpen}>
      {/*TODO: closeDrawerを呼ばないと遷移してもdrawerが閉じない */}
      <Header>
        <Close onClick={closeDrawer}>
          <CloseInner>
            <Image filename="menu/mizuhiki_close.png" />
            <CloseText>
              <Image filename="menu/close.png" />
            </CloseText>
          </CloseInner>
        </Close>
      </Header>
      <DrawerLinkList closeDrawer={closeDrawer} isOpen={isOpen} />
    </Container>
  )
}

const DrawerLinkList = ({ closeDrawer, isOpen }) => {
  return (
    <List>
      <ListContainer>
        <TextSlideUp mb={2}>
          <ListTitle isOpen={isOpen}>ページ</ListTitle>
        </TextSlideUp>
        <ListTitleLine isOpen={isOpen} />
        <LinkList>
          <TextSlideUp mb={16}>
            <Label isOpen={isOpen}>
              <Link onClick={closeDrawer} to={'/'}>
                HOME
              </Link>
            </Label>
          </TextSlideUp>
          <TextSlideUp mb={16}>
            <Label isOpen={isOpen}>
              <Link onClick={closeDrawer} to={'/accessory'}>
                アクセサリートップ
              </Link>
            </Label>
          </TextSlideUp>
          <TextSlideUp mb={16}>
            <Label isOpen={isOpen}>
              <Link onClick={closeDrawer} to={'/concept'}>
                コンセプト
              </Link>
            </Label>
          </TextSlideUp>
        </LinkList>
      </ListContainer>
      <ListContainer>
        <TextSlideUp mb={2}>
          <ListTitle isOpen={isOpen}>商品一覧</ListTitle>
        </TextSlideUp>
        <ListTitleLine isOpen={isOpen} />
        <LinkList>
          <TextSlideUp mb={16}>
            <Label isOpen={isOpen}>
              <Link onClick={closeDrawer} to={'/bracelet-list'}>
                ブレスレット
              </Link>
            </Label>
          </TextSlideUp>
          <TextSlideUp mb={16}>
            <Label isOpen={isOpen}>
              <Link onClick={closeDrawer} to={'/designbracelet-list'}>
                デザインブレスレット
              </Link>
            </Label>
          </TextSlideUp>
          <TextSlideUp mb={16}>
            <Label isOpen={isOpen}>
              <Link onClick={closeDrawer} to={'/necklace-list'}>
                ネックレス
              </Link>
            </Label>
          </TextSlideUp>
          <TextSlideUp mb={16}>
            <Label isOpen={isOpen}>
              <Link onClick={closeDrawer} to={'/stone-list'}>
                原石
              </Link>
            </Label>
          </TextSlideUp>
          <TextSlideUp mb={16}>
            <Label isOpen={isOpen}>
              <Link onClick={closeDrawer} to={'/item-list'}>
                雑貨
              </Link>
            </Label>
          </TextSlideUp>
        </LinkList>
      </ListContainer>
    </List>
  )
}
