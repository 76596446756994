import styled from '@emotion/styled'
import color from '../../styles/color'
import { breakpoints } from '../../utils/styles'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 11;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgb(221 174 38 / 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${breakpoints.m}px) {
    height: 100px;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 6px 10px;
  width: 100%;
  max-width: 1200px;
`

export const MenuLinkLogo = styled.div`
  width: 33px;

  @media (min-width: ${breakpoints.m}px) {
    width: 55px;
  }
`

export const MenuLinkh1 = styled.h1`
  font-weight: normal;
`

export const MenuRing = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 45px;
  opacity: 0.8;
  will-change: transform;
  cursor: pointer;
  img {
    transform: rotate(${props => props.scrollTop}deg);
    transition: rotate 1s linear;
  }
  transform: ${props => props.isOpen && 'scale(1.1)'};
  transition: transform 0.2s cubic-bezier(0.64, 0.07, 0.8, 0.99);

  @media (min-width: ${breakpoints.m}px) {
    width: 55px;
  }
`

export const MenuText = styled.span`
  padding-top: 4px;
  margin: 0 auto;
  width: 28px;
`
export const CartCounter = styled.div`
  position: relative;
  margin-right: 20px;
  margin-left: auto;

  @media (min-width: ${breakpoints.m}px) {
    margin-right: 30px;
  }
`

export const Bag = styled.div`
  font-size: 18px;
  color: ${color.GRAY};

  @media (min-width: ${breakpoints.m}px) {
    font-size: 24px;
  }
`

export const Count = styled.div`
  position: absolute;
  top: -3px;
  right: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  width: 12px;
  height: 12px;
  padding: 8px;
  color: ${color.WHITE};
  background-color: ${color.THEMA};
  border-radius: 50%;
`
