import styled from '@emotion/styled'
import color from '../../styles/color'
import listIcon from '../../images/icon/list-icon.png'
import { breakpoints } from '../../utils/styles'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: ${color.WHITE_GRAY};
  transform: ${props => (props.isOpen ? 'translateX(0%)' : 'translateX(100%)')};
  transition: 500ms transform cubic-bezier(0.55, 0.05, 0.22, 0.99);
  transition-delay: 0.2s;
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 18px;
  height: 60px;
`

export const Close = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 30px;
  height: 30px;
  cursor: pointer;

  @media (min-width: ${breakpoints.m}px) {
    margin-top: 30px;
    margin-right: 230px;
    width: 45px;
    height: 45px;
  }
`

export const CloseInner = styled.div`
  width: 30px;
  height: 30px;

  @media (min-width: ${breakpoints.m}px) {
    width: 45px;
    height: 45px;
  }
`

export const CloseText = styled.div`
  padding-top: 4px;
  margin: 0 auto;
  width: 28px;

  @media (min-width: ${breakpoints.m}px) {
    width: 45px;
  }
`

export const CartCounter = styled.div`
  position: relative;
  margin-right: 20px;
  margin-left: auto;
`

export const List = styled.div`
  margin: 50px 0 0 40px;
  padding-right: 16px;

  @media (min-width: ${breakpoints.m}px) {
    display: flex;
    justify-content: center;
    padding-right: 0;
    margin-top: 200px;
  }
`

export const ListContainer = styled.div`
  margin-bottom: 45px;

  @media (min-width: ${breakpoints.m}px) {
    margin-right: 100px;
  }
`

export const ListTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: ${color.GRAY};
  transform: ${props => (props.isOpen ? 'translateY(0)' : 'translateY(100%)')};
  opacity: ${props => (props.isOpen ? '1' : '0.3')};
  transition: 800ms all cubic-bezier(0.55, 0.05, 0.22, 0.99);
  transition-delay: 0.3s;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 30px;
  }
`

export const ListTitleLine = styled.div`
  height: 3px;
  border-top: 1px solid ${color.RED};
  border-bottom: 1px solid ${color.RED};
  background-color: ${color.WHITE};
  margin-bottom: 24px;
  width: ${props => (props.isOpen ? '100%' : '0%')};
  transition: 1.1s width cubic-bezier(0.55, 0.05, 0.22, 0.99);
`

export const LinkList = styled.div`
  padding-left: 12px;
`

export const Label = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  background-image: url(${listIcon});
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 0 50%;
  padding-left: 20px;
  transform: ${props => (props.isOpen ? 'translateY(0)' : 'translateY(100%)')};
  opacity: ${props => (props.isOpen ? '1' : '0.3')};
  transition: 1.1s all cubic-bezier(0.55, 0.05, 0.22, 0.99);
  transition-delay: 0.6s;
  a {
    color: ${color.GRAY};
  }

  @media (min-width: ${breakpoints.m}px) {
    font-size: 28px;
  }
`
