// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessory-js": () => import("./../../../src/pages/accessory.js" /* webpackChunkName: "component---src-pages-accessory-js" */),
  "component---src-pages-bracelet-list-js": () => import("./../../../src/pages/bracelet-list.js" /* webpackChunkName: "component---src-pages-bracelet-list-js" */),
  "component---src-pages-brandstory-js": () => import("./../../../src/pages/brandstory.js" /* webpackChunkName: "component---src-pages-brandstory-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-choice-js": () => import("./../../../src/pages/choice.js" /* webpackChunkName: "component---src-pages-choice-js" */),
  "component---src-pages-concept-js": () => import("./../../../src/pages/concept.js" /* webpackChunkName: "component---src-pages-concept-js" */),
  "component---src-pages-designbracelet-list-js": () => import("./../../../src/pages/designbracelet-list.js" /* webpackChunkName: "component---src-pages-designbracelet-list-js" */),
  "component---src-pages-feature-js": () => import("./../../../src/pages/feature.js" /* webpackChunkName: "component---src-pages-feature-js" */),
  "component---src-pages-guide-js": () => import("./../../../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-howtowear-js": () => import("./../../../src/pages/howtowear.js" /* webpackChunkName: "component---src-pages-howtowear-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-item-list-js": () => import("./../../../src/pages/item-list.js" /* webpackChunkName: "component---src-pages-item-list-js" */),
  "component---src-pages-necklace-list-js": () => import("./../../../src/pages/necklace-list.js" /* webpackChunkName: "component---src-pages-necklace-list-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-productlist-js": () => import("./../../../src/pages/productlist.js" /* webpackChunkName: "component---src-pages-productlist-js" */),
  "component---src-pages-stone-list-js": () => import("./../../../src/pages/stone-list.js" /* webpackChunkName: "component---src-pages-stone-list-js" */),
  "component---src-pages-transaction-js": () => import("./../../../src/pages/transaction.js" /* webpackChunkName: "component---src-pages-transaction-js" */),
  "component---src-templates-news-detail-index-js": () => import("./../../../src/templates/NewsDetail/index.js" /* webpackChunkName: "component---src-templates-news-detail-index-js" */),
  "component---src-templates-product-page-index-js": () => import("./../../../src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */)
}

