import React from 'react'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      * {
        box-sizing: border-box;
        font-family: a-otf-midashi-mi-ma31-pr6n, serif;
      }
      body {
        margin: 0;
      }
      html {
        font-family: a-otf-midashi-mi-ma31-pr6n, serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        -webkit-appearance: none;
      }
      a {
        text-decoration: none;

        &:hover {
          opacity: 0.6;
        }
      }
      h1,
      h2,
      h3 {
        font-size: 100%;
        margin: auto;
        padding: auto;
      }
    `}
  />
)

export const Img = styled(Image)`
  max-width: 100%;
  margin: 0;
  padding: 0;
`

export const ButtonWrapper = styled.div`
  background-color: #ddae27;
  text-align: center;
  width: 280px;
  margin: 28px auto;
  button {
    font-size: 15px;
    width: 280px;
    padding: 12px;
    background-color: transparent;
    border: none;
    color: #fff;
  }
`

export const Container = styled.div`
  margin: 0 auto;
  //max-width: 960px;
  //max-width: 800px;
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: 1auto;
  grid-template-areas: 'left . right';

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
`

export const GridRight = styled.div`
  grid-area: right;
`

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`

export const TextSlideUp = styled.div`
  margin-bottom: ${props => `${props.mb}px`};
  position: relative;
  overflow: hidden;
`
