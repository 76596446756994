import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

import ContextProvider from '~/provider/ContextProvider'

import Navigation from '~/components/Navigation'
import Footer from '../components/Footer'
import { breakpoints, GlobalStyle } from '../utils/styles'

const Wrapper = styled.div`
  margin: 0 auto;
  padding-top: 60px;

  @media (min-width: ${breakpoints.m}px) {
    padding-top: 100px;
  }
`

const Layout = ({ children }) => {
  return (
    <ContextProvider>
      <GlobalStyle />
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Navigation />
            <Wrapper>{children}</Wrapper>
            <Footer />
          </>
        )}
      />
    </ContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
