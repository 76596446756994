import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { breakpoints } from '../../utils/styles'
import color from '../../styles/color'

export const Wrapper = styled.div`
  background-color: ${color.BLACK};
  padding: 16px;
  color: ${color.WHITE_GRAY};
`
export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

export const Logo = styled.div`
  margin: 30px auto 60px;
  width: 45px;

  @media (min-width: ${breakpoints.m}px) {
    width: 60px;
    margin-bottom: 100px;
  }
`
export const Content = styled.div`
  margin-bottom: 20px;
  color: ${color.WHITE};
`

export const Sns = styled.div`
  display: flex;
  align-items: center;
`

export const SnsIcon = styled(Link)`
  font-size: 18px;
  margin-right: 12px;
  color: ${color.WHITE};

  @media (min-width: ${breakpoints.m}px) {
    font-size: 24px;
  }
`

export const FooterLink = styled(Link)`
  font-size: 12px;
  color: ${color.WHITE};

  @media (min-width: ${breakpoints.m}px) {
    font-size: 14px;
  }
`

export const Label = styled.div`
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 2px;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 16px;
  }
`

export const Text = styled.div`
  font-size: 14px;
  margin-bottom: 12px;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 16px;
  }
`

export const Copy = styled.div`
  margin: 40px 0 4px;
  font-size: 14px;
  text-align: center;

  @media (min-width: ${breakpoints.m}px) {
    margin-top: 100px;
  }
`
