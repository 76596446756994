import React, { useContext, useEffect, useState } from 'react'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'

import StoreContext from '~/context/StoreContext'
import {
  Bag,
  CartCounter,
  Container,
  Count,
  MenuLinkLogo,
  MenuLinkh1,
  MenuRing,
  Wrapper,
} from './styles'
import Image from '../image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'
import { Drawer } from '../Drawer'

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Navigation = () => {
  const [hasItems, quantity] = useQuantity()
  const [isOpen, handleDrawer] = useState(false)
  useEffect(() => {
    handleDrawer(false)
  }, [])

  const handleClose = () => handleDrawer(false)
  const handleOpen = () => handleDrawer(true)

  const scrollTop = () => {
    return Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    )
  }

  const [scrollPosition, setScrollTop] = useState(0)

  // TODO: thresh
  const onScroll = () => {
    console.log(scrollPosition)
    setScrollTop(scrollTop())
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll)
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  })

  return (
    <Wrapper>
      <Drawer isOpen={isOpen} closeDrawer={handleClose} />
      <Container>
        <MenuLinkLogo>
          <MenuLinkh1>
            <Link to="/">
              <Image filename="logo/logo_new.png" alt="ご縁をつなぐ御守り〈高天原〉天然石アクセサリー" />
            </Link>
          </MenuLinkh1>
        </MenuLinkLogo>
        <CartCounter>
          <Link to="/cart">
            {hasItems && <Count>{quantity}</Count>}
            <Bag>
              <FontAwesomeIcon icon={faShoppingCart} />
            </Bag>
          </Link>
        </CartCounter>
        <MenuRing
          onClick={handleOpen}
          scrollTop={scrollPosition / 5}
          isOpen={isOpen}
        >
          <Image filename="menu/menu-ring_bk.png" alt="メニュー" />
        </MenuRing>
      </Container>
    </Wrapper>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
